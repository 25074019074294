import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Search } from 'styled-icons/octicons/Search';
import { RiUser3Line } from 'react-icons/ri';
import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_1, COLOR_2, COLOR_3, COLOR_13, COLOR_10 } from '../../../styled/variables/variables';
import { mqTablet, mqDesktop } from '../../../styled/helpers/helpers';
import Drawer from './drawer/Drawer';
import Menu from '../menu/Menu';
import userService from '../../../services/user/user.service';
import { transitions } from 'polished';
import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventsConstants from '../../../constants/events';
import pathsConstants from '../../../constants/paths';
import { useAuth0 } from '@auth0/auth0-react';

const Navbar = ({ scrolled, isReleasePage, history }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState(userService.retrieve());
  const [userIsSubscriber, setUserIsSubscriber] = useState(false);
  const [showSearchButton, setShowSearchButton] = useState(false);

  const { loginWithRedirect } = useAuth0();

  const loadUserProfile = useCallback(async () => {
    try {
      const data = await userService.getUserProfile({ token: user.token });
      setUser(prevUser => ({ ...data, token: prevUser.token }));
      setUserIsSubscriber(data.isSubscriber);
    } catch (err) {
      console.log(err);
    }
  }, [user?.token]);

  useEffect(() => {
    if (window.location.pathname !== pathsConstants.HOME) {
      setShowSearchButton(true);
    } else {
      const scrollListener = () => {
        setShowSearchButton(window.scrollY > 300);
      };
      window.addEventListener('scroll', scrollListener, { passive: true });
      return () => window.removeEventListener('scroll', scrollListener);
    }
  }, [window.scrollY]);

  useEffect(() => {
    if (user) {
      loadUserProfile();
    }
  }, []);

  useEffect(() => {
    const handleAuthStatusChanged = event => {
      const { auth } = event.detail.params;
      setUser(auth ? userService.retrieve() : null);
    };

    domEventsUtil.subscribe({
      name: eventsConstants.AUTH_STATUS_CHANGED,
      callback: handleAuthStatusChanged,
    });

    return () => {
      domEventsUtil.unsubscribe({
        name: eventsConstants.AUTH_STATUS_CHANGED,
        callback: handleAuthStatusChanged,
      });
    };
  }, []);

  const controlShowMenuStatus = () => {
    setShowMenu(!showMenu);
  };

  const handleMenuItemClick = path => {
    if (path.includes('profile')) {
      window.open(path, '_self')
      window.location.reload();
    } else if (path && !window.location.pathname.includes(path) || path === "/") {
      window.open(path, '_self');
    }
    controlShowMenuStatus(false);
  };

  const handleToSearch = () => {
    if (window.location.pathname === '/') {
      window.scrollTo(0, 0);
    } else {
      window.open('/', '_self');
    }
  };

  const handleSignUpOrLogin = async (action) => {
    const pathRedirect = `${window.location.pathname}${window.location.search}`
    localStorage.setItem('@Koort:PathRedirect', pathRedirect);

    try {
      loginWithRedirect({ authorizationParams: { screen_hint: `${action}`, redirect_uri: `${window.location.origin}/authentication/${action}/success?${action}=true` } })
    } catch {
      toast.error("Não foi possível fazer login. Tente novamente.")
    }
  }

  return (
    <Wrapper>
      {!isReleasePage && (
        <>
          {window.location.pathname !== pathsConstants.SEARCH && showSearchButton && (
            <SearchButton type="button" onClick={handleToSearch} />
          )}
          <AdCreationButton
            scrolled={scrolled}
            onClick={() => {
              window.open(userIsSubscriber ? pathsConstants.DASHBOARD_ADS : pathsConstants.PURCHASE_CHOOSE_TYPE, '_self');
            }}
          >
            {userIsSubscriber ? 'cadastrar imóvel' : 'anunciar'}
          </AdCreationButton>
        </>
      )}

      {!user && (
        <SignUpButton
          onClick={() => handleSignUpOrLogin('signup')}
        >
          cadastre-se
        </SignUpButton>
      )}

      {!user && (
        <LoginButton
          onClick={() => handleSignUpOrLogin('login')}
        >
          <RiUser3Line />
          <Label>entrar</Label>
        </LoginButton>
      )}

      <Drawer
        highlight={showMenu}
        onClick={() => controlShowMenuStatus()}
        scrolled={scrolled}
      />
      <Menu
        onItemClick={handleMenuItemClick}
        onOutsideClick={() => controlShowMenuStatus(false)}
        show={showMenu}
        user={user}
      />
    </Wrapper>
  );
};

const SearchButton = styled(Search)`
  background-color: #f6861f;
  margin-left: 20px;
  width: 40px;
  height: 38px;
  align-self: center;
  margin-right: 12px;
  padding: 8px;
  color: #FFFFFF;
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
`;

const Wrapper = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
`;

const CTA = styled(BaseButton)`
  text-transform: uppercase;
  display: none;
  padding: 0 5px;
  background-color: ${COLOR_1};
  color: ${COLOR_2};
  border: none;
  font-weight: 700;
  min-width: 110px;
  align-items: center;
  height: 38px;
`;

const AdCreationButton = styled(CTA)`
  background-color: ${COLOR_13};
  ${mqDesktop`
    display: flex;
  `};
`;

const SignUpButton = styled(CTA)`
  ${mqTablet`
    display: flex;
  `};
`;

const LoginButton = styled.button`
  display: flex;
  cursor: pointer;
  background: transparent;
  align-items: center;
  gap: 0.25rem;
  margin: 0 1rem;

  & > label, & > svg {
    color: ${COLOR_2};
    font-size: 10pt;
  }
  &:hover {
    & > label, & > svg {
      color: ${COLOR_10};
    }
  }
`;

const Label = styled.label`
  font-weight: 300;
  display: block;
  letter-spacing: 0.5px;
  color: ${COLOR_3};
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  ${transitions(['font-weight'], 'ease .3s')}
`;

export default Navbar;
